<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Objet / sous-objet" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un objet</div>
              </v-row>
            </v-card-title>

            <InputFieldComponent label="libellé" :divider="true">
              <template v-slot:value>
                <v-text-field
                  placeholder="libellé"
                  v-model="label"
                  clearable
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les activités
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div v-for="(activity, i) in activities" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ activity.digitalName }}
                      </div>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeActivity(activity)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedActivity"
                        :items="availableActivities"
                        item-text="digitalName"
                        return-object
                        placeholder="associer une activité"
                        hint="Associer une activité puis appuyer sur le bouton '+' ou sur 'entrée'"
                        clearable
                        no-data-text="aucune activité"
                        v-on:keyup.enter="addActivity(selectedActivity)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addActivity(selectedActivity)"
                        :disabled="!selectedActivity"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize mt-2">
                    les sous-objets
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div v-for="(subobject, i) in subobjects" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ subobject }}
                      </div>

                      <v-btn
                        icon
                        color="primary"
                        @click="removeSubobject(subobject)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-text-field
                        label="ajouter un sous-objet"
                        v-model="selectedSubobject"
                        v-on:keyup.enter="addSubobject(selectedSubobject)"
                        hint="Saisir le sous-objet puis appuyer sur le bouton '+' ou sur 'entrée'"
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubobject(selectedSubobject)"
                        :disabled="
                          !selectedSubobject ||
                          this.subobjectisNotUnique(selectedSubobject)
                        "
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- <v-card-text>
              <small>*indique les champs obligatoires</small>
            </v-card-text> -->

            <!-- <v-card-text>              
              <v-alert type="info" border="left" text
                ><div style="opacity: 1">
                  Pour être utilisable dans l'application, un objet doit avoir
                  un <span :class="classForLabel">libellé</span>, être relié à
                  <span :class="classForActivities">une ou plusieurs </span
                  >activités et définir un ensemble de
                  <span :class="classForSubobjects">sous-objets</span>.
                </div></v-alert
              >
            </v-card-text> -->

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { ObjectsService } from "@/service/conf/transmission/objects_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddTransmission",
  components: { Workflow, TitleAndReturnComponent, InputFieldComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /**le libellé de l'objet */
      label: null,

      /**la liste des activités définies dans le SI */
      allActivities: [],

      /**les activités associées à l'objet */
      activities: [],

      /**l'activité sélectionnée pour l'ajout dans la liste */
      selectedActivity: null,

      /**les sous-objets associés à l'objet */
      subobjects: [],

      /**le sous-objet sélectionné pour l'ajout dans la liste */
      selectedSubobject: null,

      rules: {
        unique: (value) =>
          !this.subobjectisNotUnique(value) || "ce sous-objet existe déjà.",
      },
    };
  },
  methods: {
    async save() {
      try {
        let item = {
          label: this.label,
        };

        // 1 - on création de l'entité
        let resource = await this.service.create(item);

        // 2 - on ajoute les activités
        let activityIds = this.activities.map((a) => a.id);

        await this.service.addActivities(resource.id, activityIds);

        // 3 - on ajoute les sous-objets
        await this.service.addSubobjects(resource.id, this.subobjects);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
    /** Chargement des données */
    async load() {
      try {
        this.loading = true;

        this.allActivities = await this.service.getActivities();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Retirer une activité */
    removeActivity(activity) {
      let index = this.activities.findIndex((r) => r.id == activity.id);
      this.activities.splice(index, 1);
    },

    /** Ajouter une activité */
    addActivity(activity) {
      if (!activity) return;

      this.activities.push(activity);

      this.selectedActivity = null;
    },

    /** Retirer un sous-objet */
    removeSubobject(subobject) {
      let index = this.subobjects.findIndex((r) => r == subobject);
      this.subobjects.splice(index, 1);
    },

    /** Ajouter un sous-objet */
    addSubobject(subobject) {
      if (!subobject) return;
      // if (this.subobjectisNotUnique(this.selectedSubobject)) return;

      this.subobjects.push(subobject);

      this.selectedSubobject = null;
    },

    /** Retourne true si un sous-objet est déjà présent dans la liste */
    subobjectisNotUnique(label) {
      return this.subobjects.includes(label);
    },
  },
  computed: {
    /** Seul le label est obligatoire */
    completed() {
      if (!this.label) return false;

      if (this.activities.length === 0) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },

    /** Les activités sélectionnables dans la combobox. */
    availableActivities() {
      let availables = [];

      let ids = this.activities.map((r) => r.id);

      availables = this.allActivities.filter((r) => !ids.includes(r.id));

      return availables;
    },

    /** Est-ce que la transmission a un label? */
    hasALabel() {
      return !!this.label;
    },

    /** Est-ce que la transmission a au moins une activité? */
    hasAtLeastOneActivity() {
      return this.activities.length > 0;
    },

    /** Est-ce que la transmission a au moins un sous-objet? */
    hasAtLeastOneSubobject() {
      return this.subobjects.length > 0;
    },

    classForLabel() {
      return this.label ? "green--text" : "";
    },

    classForActivities() {
      return this.activities.length > 0 ? "green--text" : "";
    },

    classForSubobjects() {
      return this.subobjects.length > 0 ? "green--text" : "";
    },
  },
  mounted() {
    this.service = new ObjectsService(
      this.$api.getTransmissionObjectsApi(),
      this.$api.getActivitiesApi()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>